<script>
import {Modal as _BSModal} from "bootstrap";
import {getPlayerRecord} from "@/plugins/apiHelper_game";
import {mapGetters} from "vuex";
import {GameType} from "@/gameCommon";
import {debounce} from "@/utils/debounce";

export default {
  name: "PlayerRecordModal",
  data() {
    return {
      modal: undefined,
      toolTip: undefined,
      player: {},
      displayType: 'all',
      record: {
        relay: {
          'hand': "0",
          'win': "0.0",
          'vpip': "0.0",
          'pfr': "0.0",
          'c_bet': "0.0",
          '3_bet': "0.0",
          'wmsd': "0.0",
          'wtsd': "0.0",
        },
        tournament: {
          'hand': "0",
          'win': "0.0",
          'vpip': "0.0",
          'pfr': "0.0",
          'c_bet': "0.0",
          '3_bet': "0.0",
          'wmsd': "0.0",
          'wtsd': "0.0",
        },
      }
    }
  },
  computed:{
    ...mapGetters(['token']),
    avatarImage() {
      if (!(this.player && this.player.photo)) {
        const tempAvatars = [2, 6, 7, 14, 15];
        const tmpAvatar = tempAvatars[Math.floor(Math.random() * tempAvatars.length)];
        return require(`@/assets/v2/img/avatar/avatar${tmpAvatar < 10 ? '0' : ''}${tmpAvatar}.jpg`);
      }

      let avartarNumber = (this.player.photo || '').toString();
      avartarNumber = avartarNumber.match(/\d/gmi).join('');
      avartarNumber = parseInt(avartarNumber);

      return require(`@/assets/v2/img/avatar/avatar${avartarNumber < 10 ? '0' : ''}${avartarNumber}.jpg`);
    },
    modalId(){
      if(this.displayType === 'all') return 'my_layerpopup_player_info';
      else                           return 'layerpopup_player_info';
    },
    isAllRecord(){
      return this.displayType === 'all';
    },
    isRelayRecord(){
      return this.displayType === GameType.Relay;
    },
    isTournamentRecord(){
      return this.displayType === GameType.Tournament;
    },
    totalHand(){
      if( this.isAllRecord )              return Number(this.record.relay.hand) + Number(this.record.tournament.hand);
      else if( this.isRelayRecord  )      return this.record.relay.hand;
      else if( this.isTournamentRecord )  return this.record.tournament.hand;
      else                                return 0;
    },
    totalWin(){
      if( this.isAllRecord ){
        const relayWin = this.record.relay.win > 0 ? this.record.relay.hand * (this.record.relay.win / 100) : 0;
        const tournamentWin = this.record.tournament.win > 0 ? this.record.tournament.hand * (this.record.tournament.win / 100) : 0;
        const totalWin = relayWin + tournamentWin;
        const totalHand = this.totalHand;

        if( totalHand > 0 && totalWin > 0 ){
          return (totalWin / totalHand).toFixed(1) * 100;
        }else{
          return 0
        }
      }else if( this.isRelayRecord  ){
        return this.record.relay.win;
      }else if( this.isTournamentRecord ){
        return this.record.tournament.win;
      }else{
        return 0;
      }
    },
  },
  methods: {
    initRecord(){
      ['relay', 'tournament'].map(type => {
        this.record[type]['hand'] = "0";
        this.record[type]['win'] = "0.0";
        this.record[type]['vpip'] = "0.0";
        this.record[type]['pfr'] = "0.0";
        this.record[type]['c_bet'] = "0.0";
        this.record[type]['3_bet'] = "0.0";
        this.record[type]['wmsd'] = "0.0";
        this.record[type]['wtsd'] = "0.0";
      })
    },
    showPlayerRecordModal: debounce(async function ({displayType, player}) {
      try {
        this.displayType = displayType;
        this.player = player;
        const res = await getPlayerRecord({loginToken: this.token, gameType: this.displayType, playerSN: player.sn});
        if( res.data ){
          ['relay', 'tournament'].map(type => {
            this.record[type]['hand'] = Number(res.data[type]['hand']) || "0";
            this.record[type]['win'] = res.data[type]['win']?.toFixed(1) || "0.0";
            this.record[type]['vpip'] = res.data[type]['vpip']?.toFixed(1) || "0.0";
            this.record[type]['pfr'] = res.data[type]['pfr']?.toFixed(1) || "0.0";
            this.record[type]['c_bet'] = res.data[type]['c_bet']?.toFixed(1) || "0.0";
            this.record[type]['3_bet'] = res.data[type]['3_bet']?.toFixed(1) || "0.0";
            this.record[type]['wmsd'] = res.data[type]['wmsd']?.toFixed(1) || "0.0";
            this.record[type]['wtsd'] = res.data[type]['wtsd']?.toFixed(1) || "0.0";
          })
        }
      }catch (e) {
        this.$swal.fire({
          title: '요청을 처리하는 중 오류가 발생했습니다.', icon: "error",
        }).then(() => {
          this.initRecord();
          console.log(e);
        });
      } finally {
        this.showModal();
      }
    }, 1000, {leading: true}),
    showModal() {
      this.modal.show();
      this.hideToolTip();
    },
    hideModal() {
      this.modal.hide();
      this.hideToolTip();
    },
    showToolTip() {
      this.toolTip.show();
    },
    hideToolTip() {
      this.toolTip.hide();
    }
  },
  mounted() {
    this.modal = _BSModal.getOrCreateInstance($(this.$refs.root));
    this.toolTip = $(this.$refs.toolTip);
    this.EventBus.$on(`show-player-record-modal`, this.showPlayerRecordModal);
  },
  beforeDestroy() {
    this.hideModal();
    this.hideToolTip();
    this.modal = undefined;
    this.toolTip = undefined;
    this.EventBus.$off(`show-player-record-modal`);
  }
}
</script>

<template>
  <!-- 정보 -->
  <div ref="root" class="modal fade basic_modal layerpopup_player_info" :id="modalId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content tn_info_lobby">
        <div class="modal-header">
          <h1 class="modal-title tn_tit">전적 정보</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent.stop="hideModal()"></button>
        </div>
        <div class="modal-body">
          <div class="player_profile">
            <div class="profile">
              <div class="centered">
                <img :src="avatarImage" alt="">
              </div>
            </div>
            <div class="player_info">
              <div class="player_name">{{ player.name || '' }}</div>
              <div class="txt_info"><span class="gray">참여수</span>{{ totalHand }}</div>
              <div class="txt_info"><span class="gray">승률</span>{{ totalWin }}%</div>
            </div>
          </div>
          <div class="info_cnt">
            <button type="button" class="btn_tooltip" @click.prevent.stop="showToolTip()">
              <img src="@/assets/v2/svg/ico_info.svg" alt="">
            </button>
            <div v-if="isAllRecord" class="list_tit"><span class="ico_spade me-2"></span>홀덤</div>
            <div v-if="isAllRecord || isRelayRecord" class="list_info">
              <div class="item_info">
                <div class="tit">VPIP</div>
                <div class="cnt">{{ record.relay.vpip }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.relay.vpip+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">PFR</div>
                <div class="cnt">{{ record.relay.pfr }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.relay.pfr+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">C-BET</div>
                <div class="cnt">{{ record.relay['c_bet'] }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.relay['c_bet']+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">3-BET</div>
                <div class="cnt">{{ record.relay['3_bet'] }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.relay['3_bet']+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">WMSD</div>
                <div class="cnt">{{ record.relay.wmsd }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.relay.wmsd+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">WTSD</div>
                <div class="cnt">{{ record.relay.wtsd }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.relay.wtsd+'%'}"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isAllRecord" class="list_tit"><span class="ico_trophy me-2"></span>토너먼트</div>
            <div v-if="isAllRecord || isTournamentRecord" class="list_info">
              <div class="item_info">
                <div class="tit">VPIP</div>
                <div class="cnt">{{ record.tournament.vpip }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.tournament.vpip+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">PFR</div>
                <div class="cnt">{{ record.tournament.pfr }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.tournament.pfr+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">C-BET</div>
                <div class="cnt">{{ record.tournament['c_bet'] }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.tournament['c_bet']+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">3-BET</div>
                <div class="cnt">{{ record.tournament['3_bet'] }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.tournament['3_bet']+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">WMSD</div>
                <div class="cnt">{{ record.tournament.wmsd }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.tournament.wmsd+'%'}"></div>
                  </div>
                </div>
              </div>
              <div class="item_info">
                <div class="tit">WTSD</div>
                <div class="cnt">{{ record.tournament.wtsd }}%</div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{width: record.tournament.wtsd+'%'}"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="txt_info text-center">최근 30일 데이터 기준</div>
          </div>
        </div>
        <!-- 설명레이어팝업 -->
        <div ref="toolTip" class="wrap_list_desc">
          <div class="item_desc">
            <div class="tit">VPIP</div>
            <div class="desc">프리플랍에서 팟에 참여하는 빈도의 비율</div>
          </div>
          <div class="item_desc">
            <div class="tit">PFR</div>
            <div class="desc">프리플랍에서의 레이즈빈도의 비율</div>
          </div>
          <div class="item_desc">
            <div class="tit">C-BET</div>
            <div class="desc">프리플랍에서 베팅 후 플랍에서 레이즈로 이어지는 빈도의 비율</div>
          </div>
          <div class="item_desc">
            <div class="tit">3-BET</div>
            <div class="desc">레이즈 이후 상대방의 레이즈에 다시 리-레이즈하는 빈도의 비율</div>
          </div>
          <div class="item_desc">
            <div class="tit">WMSD</div>
            <div class="desc">쇼다운에 참여하여 승리한 빈도의 비율</div>
          </div>
          <div class="item_desc">
            <div class="tit">WTSD</div>
            <div class="desc">쇼다운에 참여하는 빈도의 비율</div>
          </div>
          <button type="button" class="btn_close btn btn-lg btn-warning w-100" @click.prevent.stop="hideToolTip()">닫기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>